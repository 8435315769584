<template>
  <div>
    <common-header></common-header>
    <ul class="coupons-list warp">
      <li class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <div class="price">
            <span class="unit">￥</span>
            <span class="money">{{item.amount}}</span>
          </div>
          <div class="ms">满{{item.useConditionAmount}}元可用</div>
        </div>
        <div class="border"></div>
        <div class="right">
          <div>
            <div class="title">{{item.remarks}}</div>
            <div class="time">{{(item.startTime).substring(0,11)}}至{{(item.endTime).substring(0,11)}}</div>
          </div>
          <div class="btn hui" v-if="item.quantity <= 0">已领取</div>
          <div class="btn jb-r-l" v-else-if="item.received == 0" @click="receive(item.id)">领取</div>
          <div class="btn hui" v-else>已领取</div>
        </div>
      </li>
      <no-data v-if="nodata"></no-data>
    </ul>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      list: [],
      nodata: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getPtCoupons({
        effectiveTime: true,
      }).then((res)=> {
        this.list = res.data;
        this.nodata = res.data.length > 0 ? false : true
      })
    },
    ...mapActions('account', [
      "getPtCoupons",
      "PostCoupons"
    ]),
    receive(id) {
      this.PostCoupons({
        id: id,
      }).then((res)=> {
        this.$toast("领取成功");
        this.init();
      })
    },
  },
}
</script>
<style lang='less' scoped>
.coupons-list{
  padding-top: .2rem;
  .item{
    border-radius: .05rem;
    height: 1.73rem;
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: .2rem;
    color: #ff7241;
    &.used{
      color: #666666;
      background: #e7e7e7;
    }
    .right{
      font-size: .3rem;
      padding-left: .2rem;
      display: flex;
      padding-right: .2rem;
      justify-content: space-between;
      .btn{
        width: 1.56rem;
        height: .6rem;
        border-radius: .3rem;
        color: #fff;
        text-align: center;
        line-height: .6rem;
        font-size: .3rem;
        &.hui{
          background: #e7e7e7;
          color: #919191;
        }
      }
      .time{
        font-size: .22rem;
        margin-top: .05rem;
        padding-left: .1rem;
      }
    }
    .border{
      position: absolute;
      width: 1px;
      height: 1.21rem;
      background: #ff7241;
      opacity: 0.1;
      top: 50%;
      margin-top: -.6rem;
      left: 2.12rem;
    }
    .left{
      width: 2.12rem;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &::after{
        position: absolute;
        top: -.125rem;
        right: -.125rem;
        width: .25rem;
        height: .25rem;
        background: #f7f7f7;
        border-radius: 50%;
        content: "";
      }
      &::before{
        position: absolute;
        bottom: -.125rem;
        right: -.125rem;
        width: .25rem;
        height: .25rem;
        background: #f7f7f7;
        border-radius: 50%;
        content: "";
      }
      .ms{
        font-size: .26rem;
      }
      .price{
        .unit{
          font-size: .26rem;
        }
        .money{
          font-size: .6rem;
          font-weight: bold;
        }
      }
    }
    .right{
      flex: 1;
    }
  }
}
.tab-box{
  display: flex;
  background: #ffffff;
  .item{
    flex: 1;
    text-align: center;
    height: 1rem;
    line-height: 1rem;
    font-size: .3rem;
    &.active{
      font-weight: bold;
      position: relative;
      color: #ff7241;
      &::after{
        position: absolute;
        bottom: 0;
        left: 50%;
        width: .85rem;
        height: .04rem;
        background: #ff7241;
        content: "";
        margin-left: -0.425rem;
      }
    }
  }
}
</style>